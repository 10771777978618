/*
 * @description:
 */
export default {
  appName: 'Dreo Home',
  appDescription:
    'Dreo Home is designed to provide everything you need in one place.',
  qrcodeUrl: '/assets/dreo-app-qrcode.png',
  qrcodeDes: 'Get the APP by Scan',
  appUrl: 'https://bc.dreo.com/app/',
  iosAppUrl: 'https://bc.dreo.com/app?model=official&platform=ios',
  androidAppUrl: 'https://bc.dreo.com/app?model=official&platform=android',
  iosIcon: '/assets/support/app-store.png',
  iosDes: 'Requires iOS 13.0 or above.',
  androidIcon: '/assets/support/google-play.png',
  androidsDes: 'Requires Android 8.0 or above.',
}
