import { styled } from '@mui/material/styles'
import MyLink from 'src/components/Link'

const ProductMenu = styled(MyLink)<{
  active?: string | boolean
}>(({ theme, active }) => ({
  color: '#8c8c8c',
  fontSize: 14,
  lineHeight: '34px',
  fontWeight: 500,
  display: 'inline-block',
  transition: 'opacity 200ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  textDecoration: 'none',
  '&:hover': {
    color: '#000 !important',
  },
  [theme.breakpoints.up('lg')]: {
    lineHeight: '28px',
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  [theme.breakpoints.up('xl')]: {
    paddingTop: '15px',
    paddingBottom: '15px',
  },
  ...(active && {
    color: '#000',
  }),
}))

export default ProductMenu
