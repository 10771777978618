import {
  Box,
  Grid,
  MenuItem as MuiMenuItem,
  MenuList as MuiMenuList,
} from '@mui/material'
import { SxProps } from '@mui/system'
// import WithApolloContext from 'src/modules/components/WithApolloContext'
import React, { memo, useCallback, useMemo, useRef, useState } from 'react'
import MyLink from 'src/components/Link'
import { MenuItem as HeaderMenuItem, MenuListProps } from 'types/layout'
import AppContainer from '../AppContainer'
import ProductMenus from './ProductMenus'
import SupportMenu from './SupportMenu'

// const SupportMenu = dynamic(() => import('./SupportMenu'))

const NavMenus = (
  props: MenuListProps & { window?: (() => Window) | undefined }
) => {
  const { menuList } = props
  return (
    <Box className="nav-menus" sx={{ height: '100%' }}>
      <Grid
        container
        columnSpacing={{ xs: 2, md: 3.5 }}
        columns={{ xs: 2, md: 12 }}
        justifyContent="center"
        sx={{ height: '100%' }}
      >
        {menuList.map((navItem) => (
          <Grid item key={navItem.url} sx={{ height: '100%' }}>
            <NavMenuItem {...navItem} />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

const NavMenuItem = (navItem: HeaderMenuItem) => {
  const menuContainer = useRef<HTMLDivElement | null>()
  const [menuActive, setMenuActive] = useState<boolean>(false)
  // 当前选中的分类
  const [activeCollectionHandle, setActiveCollectionHandle] = useState<
    string | undefined
  >()

  // pc 下菜单全宽
  const fullWidthMenu = useMemo(() => {
    return navItem && (navItem.isProductNav || navItem.url === '/support')
  }, [navItem])

  const navItemLinkStyles: SxProps = useMemo(
    () => ({
      fontSize: { xs: 14, xl: 18 },
      color: '#000',
      height: '100%',
      lineHeight: {
        xs: '42px',
        xl: '66px',
      },
      display: 'inline-block',
      position: 'relative',
      px: '10px',
      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: menuActive ? '100%' : 0,
        borderBottom: '2px solid #0051CF',
        transition: 'width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms',
      },
      '&:hover:after': {
        width: menuActive ? '100%' : 0,
      },
    }),
    [menuActive]
  )

  const handleChangeActiveMenu = useCallback(() => {
    setMenuActive(true)
  }, [setMenuActive])

  const handleCancelMenuActive = useCallback(() => {
    if (navItem.isProductNav) {
      setActiveCollectionHandle(undefined)
    }
    setMenuActive(false)
  }, [setMenuActive, setActiveCollectionHandle, navItem])

  return (
    <>
      <Box
        sx={{
          height: '100%',
          position: fullWidthMenu ? 'static' : 'relative',
        }}
      >
        {navItem.isProductNav || !!navItem.children ? (
          <>
            <Box
              component="span"
              onClick={handleChangeActiveMenu}
              onMouseEnter={handleChangeActiveMenu}
              onMouseLeave={handleCancelMenuActive}
              sx={{
                ...navItemLinkStyles,
                userSelect: 'none',
              }}
            >
              {navItem.title}
              {/*{JSON.stringify(menuActive)}*/}
            </Box>
            {/* 下拉菜单 */}
            <Box
              className="sub-menu-dropdown"
              sx={{
                position: 'absolute',
                top: {
                  xs: '42px',
                  xl: '66px',
                },
                left: '50%',
                transform: 'translateX(-50%)',
                overflowY: 'hidden',
                height: {
                  xs: menuActive ? 'calc( 100vh - 42px )' : 0,
                  xl: menuActive ? 'calc( 100vh - 66px )' : 0,
                },
                transition: 'height .3s linear',
                width: fullWidthMenu ? '100%' : 'auto',
                ...(fullWidthMenu
                  ? {
                      px: 0,
                    }
                  : {
                      transition: 'height .14s linear',
                      mt: '-13px',
                    }),
              }}
            >
              <Box
                className="menu-container"
                onMouseEnter={handleChangeActiveMenu}
                onMouseLeave={handleCancelMenuActive}
                ref={menuContainer}
                sx={{
                  p: menuActive && !fullWidthMenu ? '0 20px 0 20px' : 0,
                  ...(fullWidthMenu
                    ? {
                        boxShadow: '0px 3px 20px 1px rgba(0, 0, 0, 0.16)',
                        bgcolor: '#fff',
                      }
                    : {
                        p: '20px',
                      }),
                }}
              >
                {fullWidthMenu ? (
                  <AppContainer maxWidth="xl">
                    {navItem.isProductNav ? (
                      <ProductMenus
                        activeCollectionHandle={activeCollectionHandle}
                        setActiveCollectionHandle={setActiveCollectionHandle}
                        onCancel={handleCancelMenuActive}
                      />
                    ) : navItem.url === '/support' && navItem.children ? (
                      <SupportMenu
                        menus={navItem.children}
                        onCancel={handleCancelMenuActive}
                      />
                    ) : (
                      <></>
                    )}
                  </AppContainer>
                ) : (
                  navItem.children && (
                    <Box
                      sx={{
                        boxShadow: '0px 3px 20px 1px rgba(0, 0, 0, 0.16)',
                        borderRadius: '10px 10px 10px 10px',
                        bgcolor: '#fff',
                      }}
                    >
                      <MuiMenuList sx={{ py: 1.5 }}>
                        {navItem.children?.map((subMenu) => (
                          <MuiMenuItem
                            key={subMenu.url}
                            sx={{ minHeight: 46, py: 0 }}
                          >
                            <MyLink
                              sx={{
                                fontSize: { xs: 14, xl: 16 },
                                fontWeight: 500,
                                display: 'block',
                                width: '100%',
                                height: { xl: '46px', xs: '42px' },
                                lineHeight: { xl: '46px', xs: '42px' },
                              }}
                              href={subMenu.url}
                              underline="none"
                              color="#000"
                            >
                              {subMenu.title}
                            </MyLink>
                          </MuiMenuItem>
                        ))}
                      </MuiMenuList>
                    </Box>
                  )
                )}
              </Box>
            </Box>
          </>
        ) : (
          <MyLink
            href={navItem.url}
            onMouseEnter={handleChangeActiveMenu}
            onMouseLeave={handleCancelMenuActive}
            underline="none"
            sx={navItemLinkStyles}
          >
            {navItem.title}
          </MyLink>
        )}
      </Box>
    </>
  )
}

export default memo(NavMenus)
