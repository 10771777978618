import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Image from 'next/image'
import { Fragment, useCallback, useState } from 'react'
// import useCollectionsForNav from 'src/hooks/useCollectionsForNav'
import menus from './menus.json'
import ProductCategoryMenuItem from './ProductCategoryMenuItem'
import ProductMenuItem from './ProductMenuItem'

export interface ProductMenu {
  url: string
  title: string
}

export interface ProductMenusProps {
  // menus: ProductMenuItem[];
  activeCollectionHandle: string | undefined
  setActiveCollectionHandle: (value: string | undefined) => void
  onCancel: () => void
}

const ProductMenus = ({
  activeCollectionHandle,
  setActiveCollectionHandle,
  onCancel: handleClickLink,
}: ProductMenusProps) => {
  // 产品品类
  // const { data: collections } = useCollectionsForNav({
  //   query: '-accessories*',
  // })
  // // 产品配件品类
  // const { data: accessoryCollections } = useCollectionsForNav({
  //   query: 'accessories*',
  // })
  const [activeProductHandle, setActiveProductHandle] = useState<
    string | undefined
  >()

  // 分类列表
  // const productCategories = useMemo(() => {
  //   return collections
  //     ?.map((collection) => {
  //       return {
  //         url: `/collections/${collection.handle}`,
  //         title: collection.title,
  //         children: collection.products?.edges
  //           .filter(
  //             ({ node: product }) =>
  //               !product.tags?.some((tag) => tag.includes('accessories'))
  //           )
  //           .map(({ node: product }) => {
  //             return {
  //               url: `/products/${
  //                 product.landingPage?.value === 'true' ? 'landing/' : ''
  //               }${product.handle}`,
  //               title: product.seriesName?.value || product.title,
  //               imgUrl: `${product.navImage?.value}`,
  //             }
  //           }),
  //       }
  //     })
  //     .concat([
  //       // accessories
  //       {
  //         url: '/collections/accessories',
  //         title: 'Accessories',
  //         // children: accessoryCollections?.map((collection) => {
  //         //   return {
  //         //     url: `/collections/accessories/${collection.handle}`,
  //         //     title: collection.title,
  //         //     imgUrl: `${
  //         //       collection.products?.edges[0].node.navImage?.value ??
  //         //       collection.products?.edges[0].node.featuredImage?.originalSrc
  //         //     }`,
  //         //   }
  //         // }),
  //         children: [
  //           ...(accessoryCollections?.map((collection) => {
  //             return {
  //               url: `/collections/accessories/${collection.handle}`,
  //               title: collection.title,
  //               imgUrl: `${
  //                 collection.products?.edges[0].node.navImage?.value ??
  //                 collection.products?.edges[0].node.featuredImage?.originalSrc
  //               }`,
  //             }
  //           }) ?? []),
  //           {
  //             url: '/products/dreo-remote-control-for-serial-fans-heaters',
  //             title: 'Remote Control',
  //             imgUrl:
  //               'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/Frame_427319616.png?v=1692683445',
  //           },
  //         ],
  //       },
  //     ])
  // }, [collections, accessoryCollections])

  const handleOnMenuMouseEnter = useCallback(
    (collectionUrl: string) => () => {
      setActiveCollectionHandle(collectionUrl)
      setActiveProductHandle(
        menus?.find((category) => category.url === collectionUrl)?.children?.[0]
          ?.url
      )
    },
    [setActiveCollectionHandle]
  )

  return (
    <Box
      sx={{
        display: 'flex',
        pt: {
          xl: '44px',
          xs: '45px',
        },
        pb: {
          xl: '43px',
          xs: '45px',
        },
      }}
    >
      {/* 二级菜单 */}
      <Stack
        sx={{
          width: {
            xs: 130,
            xl: 190,
          },
        }}
      >
        {menus?.map((category) => (
          <Box key={category.url}>
            <ProductCategoryMenuItem
              onMouseEnter={handleOnMenuMouseEnter(category.url)}
              active={
                activeCollectionHandle === category.url ? 'true' : undefined
              }
              href={`https://${process.env.NEXT_PUBLIC_SHOPIFY_PRIMARY_DOMAIN}${category.url}`}
            >
              {category.title}
            </ProductCategoryMenuItem>
          </Box>
        ))}
      </Stack>

      {/* 三级菜单 */}
      <Box
        className="subMenus"
        sx={{
          width: {
            xs: 140,
            xl: 160,
          },
          ml: {
            xs: '42px',
            xl: '48px',
          },
          mr: {
            xs: '42px',
            xl: '114px',
          },
        }}
      >
        {menus?.map((category) => (
          <Box
            sx={{
              // visibility:
              //   activeCollectionHandle === category.url ? 'visible' : 'hidden',
              display:
                activeCollectionHandle === category.url ? 'block' : 'none',
              opacity: activeCollectionHandle === category.url ? 1 : 0,
            }}
            key={category.url}
          >
            <Stack>
              {category.children?.map((product) => (
                <Box key={product.url} py="2px">
                  <ProductMenuItem
                    href={`${
                      product.url.includes('landing')
                        ? ''
                        : `https://${process.env.NEXT_PUBLIC_SHOPIFY_PRIMARY_DOMAIN}`
                    }${product.url}`}
                    onClick={handleClickLink}
                    onMouseEnter={() => setActiveProductHandle(product.url)}
                    active={
                      activeProductHandle === product.url ? 'true' : undefined
                    }
                  >
                    {product.title}
                  </ProductMenuItem>
                </Box>
              ))}
            </Stack>
          </Box>
        ))}
      </Box>

      {/* 图片链接 */}
      <Box
        sx={{
          position: 'relative',
          display: 'block',
          width: {
            xs: 402,
            xl: 603,
          },
          height: {
            xs: 228,
            xl: 343,
          },
          bgcolor: 'rgb(249,249,249)',
        }}
      >
        <Box
          sx={{
            // visibility: !activeCollectionHandle ? 'visible' : 'hidden',
            display: !activeCollectionHandle ? 'block' : 'none',
            // opacity: !activeCollectionHandle ? 1 : 0,
            height: '100%',
            position: 'relative',
          }}
        >
          <Image
            src="https://cdn.shopify.com/s/files/1/0568/2472/5666/files/product-all.webp?v=1652950320"
            fill
            unoptimized
            alt="dreo products"
            style={{ objectFit: 'cover' }}
          />
        </Box>

        {menus?.map((category) => (
          <Fragment key={category.url}>
            {category.children?.map((product) => (
              <Box
                sx={{
                  // visibility:
                  // activeProductHandle === product.url ? 'visible' : 'hidden',
                  display:
                    activeCollectionHandle &&
                    activeProductHandle === product.url
                      ? 'block'
                      : 'none',
                  width: '100%',
                  height: '100%',
                  position: 'relative',
                }}
                key={product.url}
              >
                <ProductMenuItem
                  href={`${
                    product.url.includes('landing')
                      ? ''
                      : `https://${process.env.NEXT_PUBLIC_SHOPIFY_PRIMARY_DOMAIN}`
                  }${product.url}`}
                  display="block"
                  width="100%"
                  height="100%"
                  position="relative"
                >
                  <Image
                    src={product.imgUrl}
                    fill
                    unoptimized
                    alt={product.title}
                    style={{ objectFit: 'cover' }}
                  />
                </ProductMenuItem>
              </Box>
            ))}
          </Fragment>
        ))}
      </Box>
    </Box>
  )
}

export default ProductMenus
