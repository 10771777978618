import { NextIcon } from '@icons'
import IconEU from '@icons/countries/EU'
import IconGB from '@icons/countries/GB'
import IconJP from '@icons/countries/JP'
import IconUS from '@icons/countries/US'
import {
  Box,
  Link as MuiLink,
  Stack,
  Typography,
  TypographyProps,
} from '@mui/material'
import Image from 'next/legacy/image'
import Link from 'next/link'
import React from 'react'
import MyLink from 'src/components/Link'
import { MenuItem as HeaderMenuItem } from 'types/layout'
import { DreoApp } from '../../icons'
import applicationInfo from '../../utils/share/applicationInfo'

const SupportMenuItem = ({
  menuItem,
  dealClick,
}: {
  menuItem: HeaderMenuItem
  dealClick: () => void
}) => {
  return (
    <MyLink
      href={menuItem.url}
      className="flex items-center"
      underline={'none'}
      onClick={() => dealClick()}
    >
      <Box
        sx={{
          color: '#000',
          svg: {
            fontSize: {
              lg: 24,
            },
          },
        }}
      >
        {menuItem.icon}
      </Box>
      <div className="relative grow pl-4">
        <Typography className="text-[var(--Semantic-Color-Label-Label-Strong, #303030)] leading-[27px] font-extrabold text-[20px] mb-[2px]">
          {menuItem.title}
        </Typography>
        <Typography className="text-[var(--Semantic-Color-Label-Label-Strong, #303030)] leading-[16px] text-[12px]">
          {menuItem.subTitle}
        </Typography>
        <NextIcon
          className="absolute text-[16px] right-0 top-1/2 "
          sx={{ transform: 'translateY(-50%)' }}
        />
      </div>
    </MyLink>
  )
}

const Heading = (props: TypographyProps<'h5'>) => (
  <Typography
    component="h5"
    fontSize={18}
    fontWeight={600}
    color="var(--Semantic-Color-Label-Label-Strong, #303030)"
    mb={4.5}
    {...props}
  />
)

const StoreItem = ({
  href,
  iconUrl,
  des,
}: {
  href: string
  iconUrl: string
  des: string
}) => {
  return (
    <Box
      sx={{
        display: 'inline-block',
        textAlign: 'left',
      }}
    >
      <Link href={href} passHref legacyBehavior>
        <Box
          component="a"
          sx={{
            position: 'relative',
            display: 'inline-block',
            verticalAlign: 'middle',
            flexShrink: 0,
            width: {
              xs: 187 / 2,
              lg: 89.7,
            },
            height: {
              xs: 56 / 2,
              lg: 30,
            },
            mb: {
              lg: 0.5,
              xl: 0.7,
            },
          }}
          target="_blank"
        >
          <Image
            alt={applicationInfo.appName}
            src={iconUrl}
            layout="fill"
            unoptimized
          />
        </Box>
      </Link>

      <Typography
        className="info text-[12px]"
        sx={{
          textAlign: { xs: 'center', md: 'left' },
          lineHeight: 1.3,
        }}
      >
        {des}
      </Typography>
    </Box>
  )
}

const Application = () => {
  return (
    <div className="flex items-center xl:block">
      <Box className="flex items-center w-[280px] mb-[36px]">
        <Box
          sx={{
            borderRadius: '8px',
            overflow: 'hidden',
            mr: {
              lg: 1,
              xl: 1.4,
            },
          }}
        >
          <DreoApp
            sx={{
              fontSize: {
                xs: 50,
              },
              color: '#fff',
              alignSelf: 'flex-start',
            }}
          />
        </Box>

        <Box
          sx={{
            flex: 1,
            mt: {
              xs: 2,
              md: 0,
            },
          }}
        >
          <Typography variant="label1Bold" mb={0.25}>
            {applicationInfo.appName}
          </Typography>
          <Typography className="text-[12px]">
            {applicationInfo.appDescription}
          </Typography>
        </Box>
      </Box>
      <div>
        <Stack direction={'row'} alignItems={'center'} spacing={2}>
          <StoreItem
            href={applicationInfo.appUrl}
            iconUrl={applicationInfo.iosIcon}
            des={applicationInfo.iosDes}
          />
          <StoreItem
            href={applicationInfo.appUrl}
            iconUrl={applicationInfo.androidIcon}
            des={applicationInfo.androidsDes}
          />
        </Stack>
      </div>
    </div>
  )
}

export const SupportMenu = ({
  menus,
  onCancel,
}: {
  menus: HeaderMenuItem[]
  onCancel: () => void
}) => {
  return (
    <div className="flex py-12">
      <div>
        <div className="w-[300px]">
          <Heading>Customer Service & Support</Heading>
          <Stack spacing={4.5}>
            {menus.map((item) => {
              return (
                <SupportMenuItem
                  key={item.url}
                  menuItem={item}
                  dealClick={onCancel}
                />
              )
            })}
          </Stack>
        </div>
      </div>
      <div className="grow pl-[120px] h-full">
        <div className="relative xl:h-[349px]">
          <Heading>Contact us</Heading>
          <div className="mb-[30px] xl:mb-[60px]">
            <Typography className="text-[20px] font-extrabold text-black mb-4">
              Support Email
            </Typography>
            <Stack spacing={{ xs: 1, xl: 1 }}>
              <Stack direction={'row'} alignItems={'center'}>
                <IconUS className="mr-3 h-auto" />
                <MuiLink
                  className="text-[12px] text-black"
                  href={'mailto:support@dreo.com'}
                  underline="none"
                >
                  support@dreo.com
                </MuiLink>
              </Stack>
              <Stack direction={'row'} alignItems={'center'}>
                <IconGB className="mr-3 h-auto" />
                <MuiLink
                  className="text-[12px] text-black"
                  href={'mailto:support@dreo.com'}
                  underline="none"
                >
                  uk-support@dreo.com
                </MuiLink>
              </Stack>
              <Stack direction={'row'} alignItems={'center'}>
                <IconEU className="mr-3 h-auto" />
                <MuiLink
                  className="text-[12px] text-black"
                  href={'mailto:support@dreo.com'}
                  underline="none"
                >
                  eu-support@dreo.com
                </MuiLink>
              </Stack>
              <Stack direction={'row'} alignItems={'center'}>
                <IconJP
                  className="mr-3"
                  sx={{
                    boxShadow: '0px 0px 1px rgba(0,0,0,0.5)',
                    height: 'auto',
                  }}
                />
                <MuiLink
                  className="text-[12px] text-black"
                  href={'mailto:support.jp@dreo.com'}
                  underline="none"
                >
                  support.jp@dreo.com
                </MuiLink>
              </Stack>
            </Stack>
          </div>
          <div className="xl:absolute bottom-1 border-solid border-t border-[#E3E5E8] pt-3 max-w-[270px]">
            <Typography className="text-[20px] font-extrabold text-black mb-1">
              Wholesale and Distributors
            </Typography>
            <Typography
              className="text-[12px] text-black mb-1"
              component="a"
              href="mailto:sales@dreo.com"
            >
              sales@dreo.com
            </Typography>
          </div>
          <div className="xl:absolute right-0 bottom-0">
            <Application />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SupportMenu
