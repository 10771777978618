import { styled } from '@mui/material/styles'
import MyLink from 'src/components/Link'

const ProductCategoryMenuItem = styled(MyLink)<{
  active?: boolean | string
}>(({ theme, active }) => ({
  color: '#000',
  position: 'relative',
  display: 'inline-block',
  fontWeight: 500,
  lineHeight: '42px',
  transition: 'opacity 200ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  fontSize: 14,
  textDecoration: 'none',
  [theme.breakpoints.up('xl')]: {
    lineHeight: '58px',
    fontSize: 18,
  },
  '&:hover': {
    color: '#0051CF',
  },
  ...(active && {
    color: '#0051CF',
  }),
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: '-1px',
    left: 0,
    width: 0,
    borderBottom: '2px solid #0051CF',
    transition: 'width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    ...(active && {
      width: '100%',
    }),
  },
}))

export default ProductCategoryMenuItem
